
import { Vue, Component } from 'vue-property-decorator';
import JtAvatarUpload from "@/components/JtAvatarUpload/index.vue";
import { Loading } from "element-ui";
import {
  bindChannelPid,
  loadInviteCode,
  loadTaobaoAuth,
  loadTaobaoConfig, loadThirdPlatformConfig,
  removeTaobaoAuth,
  taoBaoAppAuth,
  updateConfig, updateThirdConfig
} from "@/api/taoke/tkTaobaoConfig";
import { removeApp } from "@/api/system/appConfig";

@Component({
  name: '3rdConfig'
})
export default class extends Vue {
  configForm:{
    haodankuKey:string
    dataokeAppKey:string
    dataokeAppSecret:string
  } = {
    haodankuKey: '',
    dataokeAppKey: '',
    dataokeAppSecret: ''
  }
  isEdit = false

  mounted(){
    this.load3rdConfig();
  }

  load3rdConfig(){
    loadThirdPlatformConfig().then(res => {
      this.configForm = res.data
    })
  }
  handleSubmitEdit(){
    updateThirdConfig(this.configForm).then(res => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.isEdit = false
    })
  }
  handleEdit(isEdit:boolean){
    this.isEdit = isEdit
  }
}
